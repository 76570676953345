<template>
  <div>
    <div class="post bg-green">
      <div class="image"><img src="../../assets/2024/teamCivilian.jpg"></div>
      <div class="post-content-wrapper">
        <div class="post-header">Traders/arms dealers</div>
        <div class="post-content">
          Civilian less legal individuals who have taken advantage of the chaotic situation in the country and want to make a quick buck selling everything to everyone at completely unreasonable prices.
        </div>
      </div>
    </div>

    <div class="post">
      <pre class="post-content-wrapper">
        <div class="post-header">Commander</div>
        <div class="post-content">
          To be announced
        </div>
      </pre>
    </div>

    <div class="post">
      <div class="post-content-wrapper">
        <div class="post-header">Traders/arms dealers</div>
        <pre class="post-content">
          <b>Unit description:</b> Arms Dealers trading with anyone who has Berget dollars. In charge of security at the trading station.
          <b>Unit size:</b> Maximum 25 soldiers
          <b>Vehicle types:</b> Class 1 - 3
          <b>Armband color:</b> Green/white
          <b>Armband marking:</b> ID number
          <b>Uniform types:</b> Civilian & PMC style
          <b>Equipment style:</b> PMC, Mixed civilian & military clothes. Hiking / Hunting clothes.
          <b>Armament:</b> Small arms, assault rifles, snipers & machine guns
          <b>Expected playstyle:</b> Larp with action.
          <b>Physical requirement level:</b> Normal
          <b>LARP elements:</b> Yes, trading and keeping the peace at the trading station.
          <b>Base & Logistics:</b> Players will have access to Berget tents, drinking water, power source 230v for battery charge & toilets.
          <b>Supply of heavy anti tank weapons:</b> low
          <b>Supply of light tank weapons:</b> medium
          <b>Mandatory equipment to be brought by the player:</b> Player armband (see rules for descriptions). Engineers need to produce and bring mines. Medics need white rags.
          <b>Team equipment provided by Berget:</b> Berget dollars to all players. Other Larp items may be handed out. Mobile mortars, TNT charges, BAVS anti-tank mine, Radios for commanders.
          <b>Unit special requirements:</b> Players in this unit are not allowed to wear a ghillie or half leaf suit.
        </pre>
      </div>
    </div>

    <div class="post-button-wrapper">
      <div @click="$router.back()" class="post-button">Back</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Civilian'
}
</script>

<style scoped>
b { color: #A7C8A0; }
</style>
