<template>
  <div>
    <div class="post bg-yellow big-text">
      <div class="image"><img src="../../assets/team6.jpg"></div>
      <div class="post-content-wrapper">
        <div class="post-header">Resistance</div>
        <div class="post-content">
          The Resistance is in possession of an weapons depot in the center of the region with access to advanced military equipment. The Resistance aim to transport as much equipment as they can out of the area to be used by their growing military force. The weapons depot is heavily fortified and possible to defend by their numbers, but not for a long period of time. Keeping control of the weapons depot will help the resistance to free their region from any oppressors and the monetary gains from their trading will help grow their numbers.
        </div>
      </div>
    </div>

    <div class="post">
      <pre class="post-content-wrapper">
        <div class="post-header">Commander</div>
        <div class="post-content">
          To be announced
        </div>
      </pre>
    </div>

    <div class="post">
      <div class="post-content-wrapper">
        <div class="post-header">Infantry</div>
        <pre class="post-content">
          <b>Unit description:</b> Infantry is a military specialization which engages in ground combat on foot.
          <b>Unit size:</b> Maximum 200 soldiers
          <b>Vehicle types:</b> None
          <b>Armband color:</b> Yellow
          <b>Armband marking:</b> ID number
          <b>Uniform types:</b> OD(olive), Woodland and Multicam.
          <b>Equipment style:</b> PMC, Mixed civilian & military clothes. Hiking / Hunting clothes.
          <b>Armament:</b> Small arms, assault rifles, snipers & machine guns
          <b>Expected playstyle:</b> Action, partly milsim.
          <b>Physical requirement level:</b> Normal
          <b>LARP elements:</b> None required
          <b>Base & Logistics:</b> Players will have access to Berget tents, drinking water, power source 230v for battery charge & toilets.
          <b>Supply of heavy anti tank weapons:</b> low
          <b>Supply of light tank weapons:</b> low
          <b>Mandatory equipment to be brought by the player:</b> Player armband (see rules for descriptions). Engineers need to produce and bring mines. Medics need white rags.
          <b>Team equipment provided by Berget:</b> Mobile mortars, TNT charges, BAVS anti-tank mine.
          <b>Unit special requirements:</b> This unit is not allowed to bring vehicles. Players in this unit are not allowed to wear a ghillie or half leaf suit.
        </pre>
      </div>
    </div>
    <div class="post">
      <div class="post-content-wrapper">
        <div class="post-header">Mechanized unit</div>
        <pre class="post-content">
          <b>Unit description:</b> A light improvised infantry vehicle unit, typically an open-backed civilian pickup truck or four-wheel drive vehicle, mounting a machine gun, anti-aircraft or anti-tank weapon.
          <b>Unit size:</b> Maximum 50 soldiers
          <b>Vehicle types:</b> Class 1 - 3
          <b>Armband color:</b> Yellow
          <b>Armband marking:</b> ID number
          <b>Uniform types:</b> OD(olive), Woodland and Multicam.
          <b>Equipment style:</b> PMC, Mixed civilian & military clothes. Hiking / Hunting clothes.
          <b>Armament:</b> Small arms, assault rifles, snipers & machine guns
          <b>Expected playstyle:</b> Action, partly milsim.
          <b>Physical requirement level:</b> Normal
          <b>LARP elements:</b> None required
          <b>Base & Logistics:</b> Players will have access to Berget tents, drinking water, power source 230v for battery charge & toilets.
          <b>Supply of heavy anti tank weapons:</b> low
          <b>Supply of light tank weapons:</b> medium
          <b>Mandatory equipment to be brought by the player:</b> Player armband (see rules for descriptions). Engineers need to produce and bring mines. Medics need white rags.
          <b>Team equipment provided by Berget:</b> Mobile mortars, TNT charges, BAVS anti-tank mine, Radios for commanders.
          <b>Unit special requirements:</b> Players in this unit are not allowed to wear a ghillie or half leaf suit.
        </pre>
      </div>
    </div>
    <div class="post-button-wrapper">
      <div @click="$router.back()" class="post-button">Back</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Resistance'
}
</script>

<style scoped>
b { color: #A7C8A0; }
</style>
